<template>
      <app-header/>
        <div class="container-fluid p-0">
            <div class="d-flex topspace-90" dir="rtl">
                <app-side-bar/>
                <Suspense>
                    <template #default>
                        <app-subscribes/>
                    </template>
                    <template #fallback>
                        <app-loading/>
                    </template>
                </Suspense>
            </div>
        </div>
    <app-footer/>
</template>

<script>
import AppFooter from '../../components/layout/AppFooter.vue'
import AppHeader from '../../components/layout/AppHeader.vue'
import AppSideBar from '../../components/layout/AppSideBar.vue'
import AppLoading from '../../components/Spinners/AppLoading.vue'
import { defineAsyncComponent, Suspense } from "vue";
const AppSubscribes = defineAsyncComponent(() =>
  import("../../components/User/AppSubscribes.vue")
);
export default {
   name: 'Subscribe',
   components: { AppHeader, AppSideBar, AppFooter, AppSubscribes, Suspense,AppLoading }
}
</script>

<style>

</style>